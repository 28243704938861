import React from 'react'

// Renders 404 message for any unknown route.
function NotFound() {
  return (
    <>
      <h1>404</h1>
      <h2>Not Found</h2>
    </>
  )
}

export {NotFound}
