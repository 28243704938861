import React from 'react'

import {AFrameScene} from '../lib/aframe-components'
import html from './cube.html'
import { splashImageComponent } from '../lib/splash'
// import { configTargetsComponent} from '../lib/config-targets'

const Scene = () => (
  <>
  <AFrameScene sceneHtml={html}
  components={[
    {name:'splash-image' , val:splashImageComponent },
    // {name:'config-targets' , val:configTargetsComponent }
  ]}
  />
  </>
)

export {Scene}
